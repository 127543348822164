import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PropertyListingDto } from 'src/app/services/property-matrixV2/models';

@Component({
  selector: 'app-listing-users',
  templateUrl: './listing-users.component.html',
  styleUrls: ['./listing-users.component.scss']
})
export class ListingUsersComponent implements OnInit {

  displayedColumns: string[] = ['fullName', 'profession', 'email', 'status', 'action'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() selectedListing: PropertyListingDto;
  dataSource: MatTableDataSource<any>;
  loading = false;

  constructor() { }

  ngOnInit(): void {
    if (this.selectedListing != undefined) {
      this.dataSource = new MatTableDataSource([]);      
    }
    else{
      this.dataSource = new MatTableDataSource([]);
    }    
  }

  RemoveUser(user: any) {
    //remove user from listing group
  }
}
