<main>
	<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
	<div class="container" *ngIf="!isMultiple">
		<mat-card class="row mb-3" *ngFor="let section of sections; let i = index">
			<div class="col-12 heading-2">
				<div class="row" [ngClass]="{'control-disabled':section.isDisabled}">
					<div class="col-11">{{ i + 1 }}. {{ section.value }}</div>
					<div class="col-1 collapse-expand" [ngClass]="{'disabled': section.isDisabled}">
						<button (click)="collapseOrExpandSection(section)" mat-icon-button>
							<mat-icon *ngIf="section.checked">expand_less</mat-icon>
							<mat-icon *ngIf="!section.checked">expand_more</mat-icon>
						</button>
					</div>
				</div>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 1" [hidden]="!section.checked">
				<app-property-detail [riskscapePropertyInfoData]="riskscapePropertyInfoData"
					[riskscapeExistingLandUseAndFeatureData]="riskscapeExistingLandUseAndFeatureData"
					(propertyType)="handlePropertyTypeChange($event)">
				</app-property-detail>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 2" [hidden]="!section.checked">
				<app-address-detail [riskscapePropertyInfoData]="riskscapePropertyInfoData">
				</app-address-detail>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 3" [hidden]="!section.checked">
				<app-locality-map [localityMapFileId]="localityMapFileId"
					(setLocalityMapFileId)="handleLocalityMapFileIdChange($event)">
				</app-locality-map>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 4" [hidden]="!section.checked">
				<app-existing-land-use [planningReportId]="planningReportId"
					(existingLandUseChange)="handleExistingLandUseChange($event)">
				</app-existing-land-use>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 5" [hidden]="!section.checked">
				<app-existing-zoning-controls [planningReportId]="planningReportId"
					(existingZoningControlsChange)="handleExistingZoningControlsChange($event)">
				</app-existing-zoning-controls>
				<app-zoning-map [planningReportId]="planningReportId" (zoningMapChange)="handleZoningMapChange($event)">
				</app-zoning-map>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 6" [hidden]="!section.checked">
				<app-spatial-framework-and-policy [planningReportId]="planningReportId"
					(spatialFrameworkChange)="handleSpatialFrameworkChange($event)">
				</app-spatial-framework-and-policy>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 7" [hidden]="!section.checked">
				<app-additional-policy-plan-figure [planningReportId]="planningReportId"
					(additionalPolicyChange)="handleAdditionalPolicyChange($event)">
				</app-additional-policy-plan-figure>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 8" [hidden]="!section.checked">
				<app-area-available [planningReportId]="planningReportId"
					(areaAvailableChange)="handleAreaAvailableChange($event)"
					(potentialUsablePropertyChange)="handlePotentialUsablePropertyChange($event)">
				</app-area-available>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 9" [hidden]="!section.checked">
				<app-improvement-option [planningReportId]="planningReportId" [disableComponent]="readonly"
					(improvementOptionChange)="handleImprovementOptionChange($event)">
				</app-improvement-option>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 10" [hidden]="!section.checked">
				<app-proposed-development-scenario [planningReportId]="planningReportId" [municipality]="municipality"
					[amountOfLandParcels]="amountOfLandParcels" [disableComponent]="readonly"
					[proposedDevelopmentEnhancement]="proposedDevelopmentEnhancement"
					(proposedDevelopmentScenariosChange)="handleProposedDevelopmentScenariosChange($event)"
					(landParcelSelectionChange)="handleLandParcelSelectionChange($event)"
					(proposedDevelopmentEnhancementChange)="handleProposedDevelopmentEnhancementChange($event)">
				</app-proposed-development-scenario>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 11" [hidden]="!section.checked">
				<app-current-construction-scope [planningReportId]="planningReportId"
					[potentialUsableProperty]="potentialUsableProperty"
					(currentConstructionScopeChange)="handleCurrentConstructionScopeChange($event)">
				</app-current-construction-scope>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 12" [hidden]="!section.checked">
				<app-potential-construction-scope [planningReportId]="planningReportId"
					[amountOfLandParcels]="amountOfLandParcels"
					[potentialConstructionScopeParagraph]="potentialConstructionScopeParagraph"
					[potentialUsableProperty]="potentialUsableProperty" [disableComponent]="readonly"
					(potentialConstructionScopesChange)="handlePotentialConstructionScopesChange($event)"
					(potentialConstructionScopeParagraphChange)="handlePotentialConstructionScopeParagraphChange($event)">
				</app-potential-construction-scope>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 13" [hidden]="!section.checked">
				<app-important-factors-to-consider [planningReportId]="planningReportId"
					(importantFactorsToConsiderChange)="handleImportantFactorsToConsiderChange($event)">
				</app-important-factors-to-consider>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 14" [hidden]="!section.checked">
				<app-cost-estimates [planningReportId]="planningReportId"
					(costEstimatesChange)="handleCostEstimatesChange($event)">
				</app-cost-estimates>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 15" [hidden]="!section.checked">
				<app-way-forward [planningReportId]="planningReportId"
					(wayForwardChange)="handleWayForwardChange($event)">
				</app-way-forward>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 16" [hidden]="!section.checked">
				<app-planning-report-reference [planningReportId]="planningReportId"
					(referenceChange)="handleReferenceChange($event)">
				</app-planning-report-reference>
			</div>
		</mat-card>
	</div>
	<div class="container" *ngIf="isMultiple">
		<div *ngIf="isGeneralInformation">
			<mat-card class="row mb-3" *ngFor="let section of sections; let i = index">
				<div class="col-12 heading-2">
					<div class="row">
						<div class="col-11">{{ section.value }}</div>
						<div class="col-1 collapse-expand">
							<button (click)="collapseOrExpandSection(section)" mat-icon-button>
								<mat-icon *ngIf="!section.checked">expand_less</mat-icon>
								<mat-icon *ngIf="section.checked">expand_more</mat-icon>
							</button>
						</div>
					</div>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 1" [hidden]="!section.checked">
					<app-locality-map (setLocalityMapFileId)="handleLocalityMapFileIdChange($event)"
						[localityMapFileId]="localityMapFileId">
					</app-locality-map>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 2" [hidden]="!section.checked">
					<app-existing-land-use [planningReportId]="planningReportId"
						(existingLandUseChange)="handleExistingLandUseChange($event)">
					</app-existing-land-use>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 3" [hidden]="!section.checked">
					<app-spatial-framework-and-policy [planningReportId]="planningReportId"
						(spatialFrameworkChange)="handleSpatialFrameworkChange($event)">
					</app-spatial-framework-and-policy>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 4" [hidden]="!section.checked">
					<app-additional-policy-plan-figure [planningReportId]="planningReportId"
						(additionalPolicyChange)="handleAdditionalPolicyChange($event)">
					</app-additional-policy-plan-figure>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 5" [hidden]="!section.checked">
					<app-area-available [planningReportId]="planningReportId"
						(areaAvailableChange)="handleAreaAvailableChange($event)"
						(potentialUsablePropertyChange)="handlePotentialUsablePropertyChange($event)">
					</app-area-available>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 6" [hidden]="!section.checked">
					<app-improvement-option [planningReportId]="planningReportId"
						(improvementOptionChange)="handleImprovementOptionChange($event)">
					</app-improvement-option>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 7" [hidden]="!section.checked">
					<app-proposed-development-scenario [planningReportId]="planningReportId"
						[municipality]="municipality" [amountOfLandParcels]="amountOfLandParcels"
						[proposedDevelopmentEnhancement]="proposedDevelopmentEnhancement"
						(proposedDevelopmentScenariosChange)="handleProposedDevelopmentScenariosChange($event)"
						(landParcelSelectionChange)="handleLandParcelSelectionChange($event)"
						(proposedDevelopmentEnhancementChange)="handleProposedDevelopmentEnhancementChange($event)">
					</app-proposed-development-scenario>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 8" [hidden]="!section.checked">
					<app-current-construction-scope [planningReportId]="planningReportId"
						[potentialUsableProperty]="potentialUsableProperty"
						(currentConstructionScopeChange)="handleCurrentConstructionScopeChange($event)">
					</app-current-construction-scope>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 9" [hidden]="!section.checked">
					<app-potential-construction-scope [planningReportId]="planningReportId"
						[amountOfLandParcels]="amountOfLandParcels"
						[potentialConstructionScopeParagraph]="potentialConstructionScopeParagraph"
						[potentialUsableProperty]="potentialUsableProperty"
						(potentialConstructionScopesChange)="handlePotentialConstructionScopesChange($event)"
						(potentialConstructionScopeParagraphChange)="handlePotentialConstructionScopeParagraphChange($event)">
					</app-potential-construction-scope>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 10" [hidden]="!section.checked">
					<app-important-factors-to-consider [planningReportId]="planningReportId"
						(importantFactorsToConsiderChange)="handleImportantFactorsToConsiderChange($event)">
					</app-important-factors-to-consider>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 11" [hidden]="!section.checked">
					<app-cost-estimates [planningReportId]="planningReportId"
						(costEstimatesChange)="handleCostEstimatesChange($event)">
					</app-cost-estimates>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 12" [hidden]="!section.checked">
					<app-way-forward [planningReportId]="planningReportId"
						(wayForwardChange)="handleWayForwardChange($event)">
					</app-way-forward>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 13" [hidden]="!section.checked">
					<app-planning-report-reference [planningReportId]="planningReportId"
						(referenceChange)="handleReferenceChange($event)">
					</app-planning-report-reference>
				</div>
			</mat-card>
		</div>
		<div *ngIf="!isGeneralInformation">
			<mat-card class="row mb-3" *ngFor="let section of sections; let i = index">
				<div class="col-12 heading-2">
					<div class="row">
						<div class="col-11">{{ section.value }}</div>
						<div class="col-1 collapse-expand">
							<button (click)="collapseOrExpandSection(section)" mat-icon-button>
								<mat-icon *ngIf="!section.checked">expand_less</mat-icon>
								<mat-icon *ngIf="section.checked">expand_more</mat-icon>
							</button>
						</div>
					</div>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 1" [hidden]="!section.checked">
					<app-property-detail [riskscapePropertyInfoData]="riskscapePropertyInfoData"
						[riskscapeExistingLandUseAndFeatureData]="riskscapeExistingLandUseAndFeatureData"
						(propertyType)="handlePropertyTypeChange($event)">
					</app-property-detail>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 2" [hidden]="!section.checked">
					<app-address-detail [riskscapePropertyInfoData]="riskscapePropertyInfoData">
					</app-address-detail>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 3" [hidden]="!section.checked">
					<app-existing-zoning-controls [planningReportId]="planningReportId"
						(existingZoningControlsChange)="handleExistingZoningControlsChange($event)">
					</app-existing-zoning-controls>
					<app-zoning-map [planningReportId]="planningReportId"
						(zoningMapChange)="handleZoningMapChange($event)">
					</app-zoning-map>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 4" [hidden]="!section.checked">
					<app-planning-report-reference [planningReportId]="planningReportId"
						(referenceChange)="handleReferenceChange($event)">
					</app-planning-report-reference>
				</div>
			</mat-card>
		</div>
	</div>
	<div class="button-group mb-3">
		<button (click)="goToListingOverview()" class="footer-button background-cloud">Previous</button>
		<button (click)="saveReportDetails()" class="footer-button background-coral">Save</button>
		<button (click)="goToAddendumOne()" class="footer-button background-green">Completed</button>
	</div>
	<mat-card class="floating-card">
		<button class="icon-button" matTooltip="Key" (click)="openReportKeyDialog()">
			<mat-icon class="material-icons-outlined">info</mat-icon>
		</button>
		<button class="icon-button" matTooltip="Save Planning Report" (click)="saveReportDetails()">
			<mat-icon class="material-icons-outlined">save</mat-icon>
		</button>
	</mat-card>
</main>
