<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<div class="title-container">
    <div class="mt-4">
        <h5>Documents</h5>
        <p>All documents related to this property</p>
        <div class="d-flex buttonAddLeft">
            <button class="center-content add-button buttonColor">
                <mat-icon class="buttonIcon">archive</mat-icon>
                <span class="paragraph buttonAdd ">Archive</span>
            </button>
            <div class="margin-left-10"></div>
            <button (click)="openNewDocumentDialog()" class="center-content add-button buttonColor">
                <mat-icon class="buttonIcon">add</mat-icon>
                <span class="paragraph buttonAdd ">Add document</span>
            </button>
        </div>
    </div>
    <div class="mt-4">
        <h6>Planning Report and Addendum's</h6>
    </div>

</div>
<mat-card class="card-container">
    <div class="documents-table-container">
        <mat-table [dataSource]="dataSource" matSort>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row class="paragraph" *matRowDef="let row; columns: displayedColumns;"></mat-row>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentNumber">
                    Document ID
                </mat-header-cell>
                <mat-cell *matCellDef="let document" class="bold">
                    #{{ document?.documentNumber }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="file">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedFileName">
                    Document Name
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    {{ document?.exportedFileName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedByUserName">
                    Exported By (Name)
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    {{ document?.exportedByUserName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedByUserEmail">
                    Exported By (Email)
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    {{ document?.exportedByUserEmail }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdDate">
                    Export Date
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    {{ formatDate(document?.createdDate) }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="download">
                <mat-header-cell *matHeaderCellDef mat-sort-header="downloadUrl">
                    Download
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    <a [href]="document?.downloadUrl" target="_blank" download>
                        <mat-icon class="material-icons-outlined download-icon">download</mat-icon>
                    </a>
                </mat-cell>
            </ng-container>
        </mat-table>
        <mat-paginator class="hidden" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent($event)">
        </mat-paginator>
    </div>
</mat-card>

<div class="title-container">
    <div class="mt-4">
        <h6>Uploads from Sell your Property</h6>
    </div>
</div>
<mat-card class="card-container">
    <div class="documents-table-container">
        <mat-table [dataSource]="dataSourceSellUploads" width="100%" matSort>
            <mat-header-row *matHeaderRowDef="displayedColumnsUploadsSellProperty"></mat-header-row>
            <mat-row class="paragraph"
                *matRowDef="let row; let i = index; columns: displayedColumnsUploadsSellProperty;"></mat-row>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.id">
                    Document ID
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index" class="bold">
                    #{{ i + 1 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="file">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.name">
                    Document Name
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentInfo?.name }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="documentType">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.fullUserName">
                    Exported By (Name)
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentType }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.fullUserName">
                    Exported By (Name)
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentInfo?.fullUserName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.userEmail">
                    Exported By (Email)
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentInfo?.userEmail }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.createdDate">
                    Export Date
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ formatDate(sellPropertyDocuments?.documentInfo?.createdDate) }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="download">
                <mat-header-cell *matHeaderCellDef mat-sort-header="downloadUrl">
                    Download
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    <a [href]="sellPropertyDocuments?.documentInfo?.fileDownloadURL" target="_blank" download>
                        <mat-icon class="material-icons-outlined download-icon">download</mat-icon>
                    </a>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef mat-sort-header="actions">
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <ng-container *ngFor="let option of actionOptions">
                            <button mat-menu-item (click)="option.action(element)">{{ option.label }}</button>
                        </ng-container>
                    </mat-menu>
                </mat-cell>
            </ng-container>

        </mat-table>
       
        <mat-paginator class="hidden" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent($event)">
        </mat-paginator>
    </div>
</mat-card>

<div class="title-container">
    <div class="mt-4">
        <h6>Pictures of property</h6>
    </div>
</div>
<mat-card class="card-container">
    <div class="documents-table-container">
        <mat-table [dataSource]="dataSourcePicture" matSort>
            <mat-header-row *matHeaderRowDef="displayedColumnsUploadsSellProperty"></mat-header-row>
            <mat-row class="paragraph"
                *matRowDef="let row; let i = index; columns: displayedColumnsUploadsSellProperty;"></mat-row>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.id">
                    Document ID
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index" class="bold">
                    #{{ i+ 1 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="file">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.name">
                    Document Name
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentInfo?.name }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="documentType">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.fullUserName">
                    Exported By (Name)
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentType }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.fullUserName">
                    Exported By (Name)
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentInfo?.fullUserName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.userEmail">
                    Exported By (Email)
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentType }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.createdDate">
                    Export Date
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ formatDate(sellPropertyDocuments?.documentInfo?.createdDate) }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="download">
                <mat-header-cell *matHeaderCellDef mat-sort-header="downloadUrl">
                    Download
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    <a [href]="sellPropertyDocuments?.documentInfo?.fileDownloadURL" target="_blank" download>
                        <mat-icon class="material-icons-outlined download-icon">download</mat-icon>
                    </a>
                </mat-cell>
            </ng-container>

        </mat-table>
        <mat-paginator class="hidden" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent($event)">
        </mat-paginator>
    </div>
</mat-card>

<div class="title-container">
    <div class="mt-4">
        <h6>Additional Uploads</h6>
    </div>
</div>
<mat-card class="card-container">
    <div class="documents-table-container">
        <mat-table [dataSource]="dataSourceAddition" matSort>
            <mat-header-row *matHeaderRowDef="displayedColumnsAdditionalUpload"></mat-header-row>
            <mat-row class="paragraph"
                *matRowDef="let row; let i = index; columns: displayedColumnsAdditionalUpload;"></mat-row>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentNumber">
                    Document ID
                </mat-header-cell>
                <mat-cell *matCellDef="let additionalImage; let i = index" class="bold">
                    #{{ i + 1 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="file">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedFileName">
                    Document Name
                </mat-header-cell>
                <mat-cell *matCellDef="let additionalImage; let i = index">
                    {{ additionalImage?.documentInfo?.name }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="documentType">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.fullUserName">
                    Exported By (Name)
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentType }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedByUserName">
                    Network Planner
                </mat-header-cell>
                <mat-cell *matCellDef="let additionalImage; let i = index">
                    {{ additionalImage?.documentInfo?.fullUserName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedByUserEmail">
                    Network Planner (Email)
                </mat-header-cell>
                <mat-cell *matCellDef="let additionalImage; let i = index">
                    {{ additionalImage?.documentInfo?.userEmail }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdDate">
                    Export Date
                </mat-header-cell>
                <mat-cell *matCellDef="let additionalImage; let i = index">
                    {{ formatDate(additionalImage?.documentInfo.createdDate) }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="download">
                <mat-header-cell *matHeaderCellDef mat-sort-header="downloadUrl">
                    Download
                </mat-header-cell>
                <mat-cell *matCellDef="let additionalImage; let i = index">
                    <a [href]="additionalImage?.documentInfo?.fileDownloadURL" target="_blank" download>
                        <mat-icon class="material-icons-outlined download-icon">download</mat-icon>
                    </a>
                </mat-cell>
            </ng-container>
        </mat-table>
        <mat-paginator class="hidden" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent($event)">
        </mat-paginator>
    </div>
</mat-card>
