<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<div class="title-container">
    <div class="mt-4">
        <h5>Matrix Network</h5>
        <p>All users related to this property</p>
    </div>
</div>
<mat-card class="card-container">
    <div class="users-table-container">
        <mat-table [dataSource]="dataSource" class="task-table" matSort>
            <ng-container matColumnDef="fullName">
                <mat-header-cell *matHeaderCellDef> FULL NAME </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{user.name + ' ' + user.surname}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="profession">
                <mat-header-cell *matHeaderCellDef> PROFESSION </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{user.profession}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef> EMAIL </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{user.email}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> STATUS </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{user.status}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef> ACTION </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <button mat-button (click)="editUser(user)">Edit</button>
                    <button mat-button (click)="deleteUser(user)">Delete</button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row class="paragraph" *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div *ngIf="dataSource.data.length === 0" class="no-records">
            No Users found.
        </div>
    </div>
</mat-card>