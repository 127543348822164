import { PropertyListingDto, ReportDocumentDto, SellMyPropertyDocumentDto } from 'src/app/services/property-matrixV2/models';
import { ConfigUserPermissionService } from 'src/app/services/property-matrixV2/custom-services/userPermission.service';
import { sellYourPropertyUploadDocumentTabTypes } from 'src/app/shared/interfaces/sell-your-property-filetypes';
import { ColumnConfig, DataTableComponent } from 'src/app/shared/components/data-table/data-table.component';
import { PlanningReportService, UserManagementService } from 'src/app/services/property-matrixV2/services';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { User } from 'src/app/models/User';

import { ListingDocumentAddEditComponent } from '../listing-document-add-edit/listing-document-add-edit.component';
import { UserActionDialogComponent } from '../../../admin-users/user-action-dialog/user-action-dialog.component';

export interface ActionOption {
  label: string;
  action: (user: any) => void;
}


@Component({
  selector: 'app-listing-documents',
  templateUrl: './listing-documents.component.html',
  styleUrls: ['./listing-documents.component.scss'],
  providers: [DatePipe]
})
export class ListingDocumentsComponent implements OnInit {

  @Input() planningReportId: string;
  @Input() selectedListing: PropertyListingDto;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  loading: boolean = false;
  documents: ReportDocumentDto[] = [];
  displayedColumns: string[] = ['number', 'file', 'user', 'email', 'date', 'download'];
  displayedColumnsUploadsSellProperty: string[] = ['number', 'file', 'user', 'email', 'date', 'download'];
  displayedColumnsPropertyPics: string[] = ['number', 'file', 'user', 'email', 'date', 'download'];
  displayedColumnsAdditionalUpload: string[] = ['number', 'file', 'user', 'email', 'date', 'download'];
  dataSource = new MatTableDataSource(this.documents);

  sellPropertyDocuments: SellMyPropertyDocumentDto[] = [];
  additionImages: SellMyPropertyDocumentDto[] = [];
  dataSourceSellUploads = new MatTableDataSource(this.sellPropertyDocuments);
  dataSourcePicture = new MatTableDataSource(this.sellPropertyDocuments);
  dataSourceAddition = new MatTableDataSource(this.additionImages);

  data = [
    { number: '', file: '', email: '', user: '', date: '', download: '', actions: '' },
  ];

  columns: ColumnConfig[] = [
    { columnDef: 'number', header: 'Document ID', headerAlign: 'left', cellAlign: 'left', width: 20 },
    { columnDef: 'file', header: 'File', headerAlign: 'left', cellAlign: 'left', width: 10 },
    { columnDef: 'user', header: 'User', headerAlign: 'left', cellAlign: 'left', width: 20 },
    { columnDef: 'email', header: 'Email', headerAlign: 'left', cellAlign: 'left', width: 10 },
    { columnDef: 'date', header: 'Date', headerAlign: 'left', cellAlign: 'left', width: 10 },
    { columnDef: 'download', header: 'Download', headerAlign: 'left', cellAlign: 'left', width: 20 },
    { columnDef: 'actions', header: 'Actions', isAction: true, headerAlign: 'center', cellAlign: 'center', width: 10 }
  ];

  actionOptions: ActionOption[] = [
    { label: 'Remove', action: (SellMyPropertyDocument: SellMyPropertyDocumentDto) => this.openActionDialog('remove', SellMyPropertyDocument) },
    { label: 'Edit', action: (SellMyPropertyDocument: SellMyPropertyDocumentDto) => this.openEditUploadDialog('edit', SellMyPropertyDocument) },
  ];

  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 20, 50, 75, 100];



  requiredRolesFull: string[] = [];
  requiredRolesView: string[] = [];
  currentUserRoles: string[];

  constructor(
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private _userPermissionService: ConfigUserPermissionService,
    private userManagementService: UserManagementService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.getDocuments();
    this.getSellPropertyDocuments();
  }

  getDocuments() {
    if (this.planningReportId === null || this.planningReportId === undefined) {
      this.notificationService.showInfoMessage('Notice', 'The planning report needs to be exported before documents will be available here.');
    } else {
      this.loading = true;
      this.planningReportService.apiV1PlanningReportGetReportDocumentsGet({
        planningReportId: this.planningReportId
      }).subscribe({
        next: (documents: ReportDocumentDto[]) => {
          this.documents = documents;
          this.dataSource.data = this.documents;
          this.dataSource.sort = this.sort;
          this.loading = false;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load report documents.');
          this.loading = false;
        }
      });
    }
  }

  populateTableData(sellMyPropertyDocument: SellMyPropertyDocumentDto[]) {
    return sellMyPropertyDocument.map(sellDocument => ({
      number: '',
      file: sellDocument?.documentInfo?.name,
      user: sellDocument?.documentInfo?.fullUserName,
      email: sellDocument?.documentInfo?.userEmail,
      date: this.formatDate(sellDocument?.documentInfo?.createdDate),
      download: sellDocument.documentInfo?.fileDownloadURL,
      actions: '',
    }));
  }

  getSellPropertyDocuments() {
    if (this.planningReportId === null || this.planningReportId === undefined) {
      this.notificationService.showInfoMessage('Notice', 'The planning report needs id.');
    } else {
      this.loading = true;
      this.planningReportService.apiV1PlanningReportGetReportUploadedSellPropertyDocumentsGet({
        propertyDetailId: this.selectedListing.propertyDetail.id
      }).subscribe({
        next: (UploadedDocument: SellMyPropertyDocumentDto[]) => {
          this.sellPropertyDocuments = UploadedDocument;
          this.dataSourceSellUploads.data = this.sellPropertyDocuments.filter(d => d.documentType == sellYourPropertyUploadDocumentTabTypes.RequiredDocument.toString() || d.documentType == sellYourPropertyUploadDocumentTabTypes.PropertyInfoDocument.toString() || d.documentType == sellYourPropertyUploadDocumentTabTypes.AdditionalDocument.toString());
          let newDataSourceWithActions = this.populateTableData(this.sellPropertyDocuments);
          this.dataSourceSellUploads.sort = this.sort;
          this.dataSourceSellUploads.paginator = this.dataSourceSellUploads.paginator;

          this.dataSourcePicture.data = this.sellPropertyDocuments.filter(x => x.documentType == sellYourPropertyUploadDocumentTabTypes.PictureDocument.toString());
          this.dataSourcePicture.sort = this.sort;
          this.dataSourcePicture.paginator = this.dataSourceSellUploads.paginator;

          this.additionImages = UploadedDocument;
          this.dataSourceAddition.data = this.additionImages.filter(a => a.documentType == sellYourPropertyUploadDocumentTabTypes.NewAddedAdditionalDocument.toString());
          this.dataSourceAddition.sort = this.sort;
          this.dataSourceAddition.paginator = this.dataSourceSellUploads.paginator;
          this.loading = false;
          console.log(this.data);
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load Sell property uploads.');
          this.loading = false;
        }
      });
    }
  }

  getAdditionalImages() {
    if (this.planningReportId === null || this.planningReportId === undefined) {
      this.notificationService.showInfoMessage('Notice', 'The planning report needs to be exported before documents will be available here.');
    } else {
      this.loading = true;
      this.planningReportService.apiV1PlanningReportGetReportUploadedSellPropertyDocumentsGet({
        propertyDetailId: this.selectedListing.propertyDetail.id
      }).subscribe({
        next: (UploadedDocument: []) => {
          if (UploadedDocument) {
            this.additionImages = UploadedDocument;
            this.dataSourceAddition.data = this.additionImages;
            this.dataSourceAddition.sort = this.sort;
            this.dataSourceAddition.paginator = this.dataSourceSellUploads.paginator;
            this.dataSourceAddition.filterPredicate = (data: any, filter: string) => {
              const searchStr = filter.toLowerCase();
              return data.fullName.toLowerCase().includes(searchStr) ||
                data.email.toLowerCase().includes(searchStr) ||
                data.tags.toLowerCase().includes(searchStr);
            };
          }
          this.loading = false;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load Sell property uploads.');
          this.loading = false;
        }
      });
    }
  }

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'dd MMMM yyyy hh:mm');
  }

  openNewDocumentDialog(): void {
    const AddNewUploadDialog = this.dialog.open(ListingDocumentAddEditComponent, {
      data: {
        fields: null,
        properDetailId: this.selectedListing.propertyDetail.id,
      },
      width: '30vw',
      height: '90vh'
    });
    AddNewUploadDialog.afterClosed().subscribe(result => {
      this.getDocuments();
      this.getSellPropertyDocuments();
    });
  }

  openActionDialog(actionType: string, user: SellMyPropertyDocumentDto): void {
    if (this.getActionsForUser().includes('edit')) {
      const dialogRef = this.dialog.open(UserActionDialogComponent, {
        width: '30vw',
        height: '30vh',
        data: { actionType, user }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getDocuments();
          this.getSellPropertyDocuments();
        }
      });
    } else {
      this.notificationService.showWarningMessage("Insufficient role", "Current logged in user does not have edit permissions or role linked.");
    }
  }

  openEditUploadDialog(actionType: string, sellMyPropertyDocument: SellMyPropertyDocumentDto): void {
    if (this.getActionsForUser().includes('edit')) {
      if (sellMyPropertyDocument != null) {

        const editUserDialog = this.dialog.open(ListingDocumentAddEditComponent, {
          data: {
            fields: sellMyPropertyDocument,
            properDetailId: this.selectedListing.propertyDetail.id,
          },
          width: '30vw',
          height: '90vh'
        });
        editUserDialog.afterClosed().subscribe(result => {
          this.getDocuments();
          this.getSellPropertyDocuments();
        });
      } else {
        this.notificationService.showErrorMessage('Error', 'No current data selected.');
      }
    } else {
      this.notificationService.showWarningMessage("Insufficient role", "Current logged in user does not have edit permissions or role linked.");
    }
  }

  pageEvent(event: any) {
    console.log(event);
  }

  private getActionsForUser() {
    if (this.hasRequiredRoleFull()) {
      return ['view', 'edit'];
    } else if (this.hasRequiredRoleView()) {
      return ['view'];
    }
    return [];
  }

  private hasRequiredRoleFull(): boolean {
    return this.currentUserRoles.some(role => this.requiredRolesFull.includes(role));
  }

  private hasRequiredRoleView(): boolean {
    return this.currentUserRoles.some(role => this.requiredRolesView.includes(role));
  }

  private getUserDetail() {
    let user = sessionStorage.getItem('user');
    if (user != null) {
      let currentUser = JSON.parse(JSON.parse(user)) as User;

      for (let index = 0; index < currentUser.roles.length; index++) {
        var tokenRoles = currentUser.roles[index];
        this.currentUserRoles.push(tokenRoles);
      }
    } else {
      this.loadCurrentUserRole();
    }
  }

  private loadCurrentUserRole(): void {
    this.userManagementService.apiV1UserManagementGetUserTokenGet().subscribe(roleBasedAccessDto => {
      this.currentUserRoles = roleBasedAccessDto.roles;
    });
  }

  private getUserPermissionList(): void {
    this._userPermissionService.getRequiredRoles().subscribe(data => {
      this.requiredRolesFull = data.requiredRolesFull;
      this.requiredRolesView = data.requiredRolesView;
    });
  }
}