<div class="loading" *ngIf="loading == true">Loading&#8230;</div>

<main class="dialog-container">
    <div>
        <span class="paragraph bold user-dialog-heading">{{titleText}}</span>
        <div class="row-container pt-1 pb-1">
            <span class="paragraph user-dialog-subheading">Invite a new user to join the platform</span>
        </div>
        <form class="user-form" [formGroup]="userDataForm" id="FormEditAddUserForm">
            <div class="row paragraph">
                <div class="col-12 mt-2">
                    <div class="form-group">
                        <label class="col-12 bold">First Name</label>
                        <input class="col-12 mt-1" type="text" placeholder="John" formControlName="firstName" />
                    </div>
                    <div *ngIf="userDataForm.get('firstName').touched && userDataForm.get('firstName').invalid">
                        <div class="errorText" *ngIf="userDataForm.get('firstName').hasError('required')">
                            First Name is
                            required.
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-2">
                    <div class="form-group"></div>
                    <label class="col-12 bold">Last Name</label>
                    <input class="col-12 mt-1" type="text" placeholder="Doe" formControlName="surname" />
                    <div *ngIf="userDataForm.get('surname').touched && userDataForm.get('surname').invalid">
                        <div class="errorText" *ngIf="userDataForm.get('surname').hasError('required')">
                            Last Name is
                            required.
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-2">
                    <div class="form-group">
                        <label class="col-12 bold">Email</label>
                        <input class="col-12 mt-1" type="text" placeholder="johndoe@gmail.com"
                            formControlName="email" />
                        <div *ngIf="userDataForm.get('email').touched && userDataForm.get('email').invalid">
                            <div class="errorText" *ngIf="userDataForm.get('email').hasError('required')">
                                Email is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-2">
                    <label class="col-12 bold">Role</label>
                    <mat-select class="col-12 mt-1" [(value)]="selectedRole" [compareWith]="compareUserRole">
                        <mat-option [value]="null">None</mat-option>
                        <mat-option *ngFor="let roleItem of roleList" [value]="roleItem">
                            {{roleItem.name}}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="col-12 mt-2">
                    <div class="form-group" *ngIf="selectedRole?.name === 'Extended Profession'">
                        <label class="col-12 bold">Profession</label>
                        <mat-select class="col-12 mt-1" [(value)]="selectedProfession"
                            [compareWith]="compareProfession">
                            <mat-option [value]="null">None</mat-option>
                            <mat-option *ngFor="let professionItem of professionList" [value]="professionItem">
                                {{professionItem.value}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="col-12 mt-2">
                    <label class="col-12 bold">Associated Network Planner (Optional)</label>
                    <input class="col-12 mt-1" type="text" placeholder="Associated Network Planner"
                        formControlName="associatedNetworkPlanner" />
                </div>
                <div class="col-12 mt-2">
                    <div class="form-group">
                        <label class="col-12 bold">Password (Optional)</label>
                        <input class="col-12 mt-1" type="text" placeholder="Password (Optional)"
                            formControlName="password" />
                    </div>
                </div>
                <div class="col-12 mt-2">
                    <div class="form-group">
                        <label class="col-12 bold">Metro</label>
                        <mat-select class="col-12 mt-1" [(value)]="selectedMetro" [compareWith]="compareMetro">
                            <mat-option *ngFor="let metroItem of metroList" [value]="metroItem">
                                {{metroItem.description}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="col-12 mt-2">
                    <div class="row paragraph mt-4">
                        <div class="form-group">
                            <label class="bold mb-2">
                                Endorsed this current account?
                            </label>
                            <mat-radio-group formControlName="accountEndorsed">
                                <mat-radio-button class="mt-1" [value]="true">Yes</mat-radio-button>
                                <mat-radio-button class="mt-1" [value]="false">No</mat-radio-button>
                            </mat-radio-group>
                            <div
                                *ngIf="userDataForm.get('accountEndorsed').touched && userDataForm.get('accountEndorsed').invalid">
                                <div class="errorText" *ngIf="userDataForm.get('accountEndorsed').hasError('required')">
                                    Account
                                    Endorsed is
                                    required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="button-group mt-3">
            <button class="footer-button background-cloud" (click)="closeDialog()" type="button">
                Cancel
            </button>
            <button class="footer-button background-coral" (click)="confirmSave()" type="button">
                Confirm
            </button>
        </div>
    </div>
</main>